import React, { useState } from 'react';
import Layout from '../../components/Layout';
import { Helmet } from 'react-helmet';
import './Help.css';
import { MaxWEnum } from '../../helpers/FormEnum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { getBackgroundHover, getTextLinkStyleClass } from '../../config/FormSettings';
import { Link } from 'react-router-dom';
import Faq from '../../components/faq/Faq';

const Help: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleAnswer = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Frequently Asked Questions / Help</title>
      </Helmet>

      <div className={`${MaxWEnum.MaxWXl} w-full`}>
        <div className='text-left w-full my-4'>
          <h2 className='font-semibold text-lg'>Frequently Asked Questions</h2>
        </div>
        <Faq
          faq={
            [
              {
                question: "How to get the url of an instagram post?",
                answer: `
Follow these steps to find the URL of an Instagram post on your computer:
- Open the post and click on the 3 dots icon in the top right corner of the photo.
- Click on Copy link.
On Mobile:
- Open the post and click on the share icon is like paper plane in the bottom of the photo.
- Click on Copy link.
                                `
              },
              {
                question: "Why should I sign up?",
                answer: "1. All your transactions are recorded on our secure servers regardless of your device and browser.\n2. You can easily find your previous giveaways.\n3. You have a public profile which you can share with your followers.\n4. Public code certificate for your Giveaways which are easily accessible by anyone with the code."
              },
              {
                question: "How can I find the link to my giveaway post?",
                answer: "Click on the three dots at the top right of your Instagram post, similar for other platforms we support like Youtube, X, TikTok, etc., copy the link and paste your copied giveaway's link into the field in the socialspicker.com giveaway tools"
              },
              {
                question: "Can I add conditions and filters to Giveaways?",
                answer: "Yes, you can add filters to force participants to comply with certain conditions, such as @mentions, #hashtags, or filter duplicate users."
              },
              {
                question: "Can I filter who has shared my post in Stories?",
                answer: "No. We can't access the Stories of all giveaway participants to verify if they've shared your post. You will have to verify it yourself when running the giveaway and get the winners."
              },
              {
                question: "Can I download a list of giveaway participants?",
                answer: "Unfortunately, no."
              },
              {
                question: "Can I get a payment invoice?",
                answer: "It is important to us that you have a track record of all your payments, so you can download your payment invoice from the billing section of your account. You can also add your Business name, address and tax id."
              },
            ]
          }
        />
      </div>
    </Layout>
  );
};

export default Help;