import React, { useEffect, useState } from 'react';
import notificationService from '../../../services/NotificationService';
import { useIsLoading } from '../../../hooks/useIsLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { LoadingMessageEnum as LoadingMessage } from '../../../helpers/LoadingMessageEnum';
import { useLoadingMessage } from '../../../hooks/useLoadingMessage';
import { SubmitHandler, useForm } from 'react-hook-form';
import loggerService from '../../../services/LoggerService';
import { RoutesEnum as AppRoutes } from '../../../routes/RoutesEnum';
import { SocialPlatformEnum as SocialPlatform } from '../../../helpers/SocialPlatformEnum';
import { CampaignStateDataDTO } from '../../../dto/CampaignStateDataDTO';
import { YesNoEnum } from '../../../helpers/YesNoEnum';
import { isShortlinkInstagramUrl, isValidInstagramUrl, setNavigateCampaignState } from '../../../helpers/CampaignHelper';
import { getButtonPrimaryStyleClass, getInputTextStyleClass } from '../../../config/FormSettings';
import { useCampaignContext } from '../../../contexts/CampaignContext';
import { CampaignTypeEnum } from '../../../helpers/CampaignTypeEnum';
import { ensureTrailingSlash } from '../../../helpers/StringsHelper';
import { isRouteActive } from '../../../helpers/RoutesHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../auth/contexts/AuthContext';
import { faComments, faCopy, faHeart, faPaste } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../Tooltip';
import { CampaignViewEnum } from '../../../helpers/CampaignViewEnum';
import PasteBtn from '../../PasteBtn';
import Faq from '../../faq/Faq';
import ReusableAcceptCancelModal from '../../modals/ReusableAcceptCancelModal';

interface InstagramUrlAppFormInputs {
    url: string;
}

const InstagramUrlApp: React.FC = () => {
    const { setCampaignStateDataDTO, campaignStateDataDTO, setCampaignViewEnum, unmountCampaignRevertDefaults } = useCampaignContext();
    const { setIsAllowLoggedInFromHome } = useAuthContext();
    const location = useLocation();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<InstagramUrlAppFormInputs>();
    const [isReusableAcceptCancelModal, setIsReusableAcceptCancelModal] = useState<boolean>(false);
    const [url, setUrl] = useState<string>();

    const { isLoading, setIsLoading } = useIsLoading();
    const { loadingMessage, setLoadingMessage } = useLoadingMessage();
    const navigate = useNavigate();

    const handleCampaignStateData = (url: string) => {
        const campaignStateDataDTO: CampaignStateDataDTO = {
            SocialPlatform: SocialPlatform.Instagram,
            CampaignType: CampaignTypeEnum.Comments,
            Referrer: AppRoutes.APP_INSTAGRAM_URL,
            Api: YesNoEnum.No,
            Url: ensureTrailingSlash(url),
            SocialPlatformIcon: faInstagram,
            SocialPlatformLikeIcon: faHeart,
            SocialPlatformClassName: "text-instagram",
            CommentsSocialIcon: faComments,
            CommentsDisplayLabel: "Comments",
            UsernameDisplay: "@",
            IsNotAvailableMessage: "Invalid instagram post or reel URL",
            ParticipantsCount: 0,
            ParticipantsCountDisplay: "0",
        };

        setCampaignViewEnum(CampaignViewEnum.Preview);
        setCampaignStateDataDTO(campaignStateDataDTO);

        setNavigateCampaignState(navigate);
    }

    useEffect(() => {
        unmountCampaignRevertDefaults();
    }, []);

    useEffect(() => {
        if (isRouteActive(location, AppRoutes.HOME)) {
            setIsAllowLoggedInFromHome(true);
        }

        return () => {
            setIsAllowLoggedInFromHome(false);
        };
    }, [campaignStateDataDTO]);

    const onSubmit: SubmitHandler<InstagramUrlAppFormInputs> = async (data) => {
        const handleSubmitForm = async () => {
            setIsLoading(true);
            setLoadingMessage(LoadingMessage.Message);

            try {
                if (!isValidInstagramUrl(data.url)) {
                    notificationService.setMessage({
                        message: 'Invalid Instagram photo or reel URL',
                        status: false
                    });

                    return;
                }

                if (isShortlinkInstagramUrl(data.url)) {
                    setUrl(data.url);

                    setIsReusableAcceptCancelModal(true);

                    return;
                }

                handleCampaignStateData(data.url);
            } catch (error) {
                notificationService.setMessage({
                    message: 'Failed, try again.',
                    status: false
                });

                loggerService.error(error);
            }
            finally {
                setIsLoading(false);
            }
        };

        handleSubmitForm();
    };

    const handleClipboardRead = async () => {
        try {
            navigator.clipboard.readText().then((text) => setValue("url", text));
        } catch (err) {
            //no permission
        }
    };

    return (
        <>
            <h1 className="text-2xl font-bold mb-4">Instagram Comment Picker</h1>
            <p className="text-center mb-4">Pick a random comment winner from your Instagram photos and reels</p>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-md">
                <div className="flex items-center py-2">
                    <FontAwesomeIcon icon={faInstagram} className="mr-2 text-instagram" />
                    <input
                        type="text"
                        {...register('url', { required: 'URL is required' })}
                        placeholder="Enter an Instagram Photo or Reel URL"
                        className={`${getInputTextStyleClass()} w-full py-2 px-2`}
                    />
                    <PasteBtn onClick={handleClipboardRead} />
                </div>
                <div className="w-full text-center py-1">
                    {errors.url && <p className="text-red-500 text-sm">{errors.url.message}</p>}
                </div>
                <button
                    type="submit"
                    className={`${getButtonPrimaryStyleClass(true)} w-full p-2`}
                    disabled={isLoading}
                >
                    {isLoading ? loadingMessage : "Start"}
                </button>

                <div className='mt-10'>
                    <Faq
                        faq={
                            [
                                {
                                    question: "How to get the url of an instagram post?",
                                    image: "/assets/info/instagramInfo.gif",
                                },
                            ]
                        }
                    />
                </div>
            </form>

            <ReusableAcceptCancelModal
                isOpen={isReusableAcceptCancelModal}
                setValue={() => setIsReusableAcceptCancelModal(false)}
                title='Instagram Giveaway'
                description={
                    <>
                        <div className='w-full mb-4 whitespace-pre-line'>
                            1. We noticed you use a copied shortlink from Instagram<br></br>
                            2. Click to Copy the url from below and paste it in a new tab in browser<br></br>
                            <div className="mr-4 mt-2 inline-block text-center rounded shadow-md bg-gray-100 p-1 cursor-pointer block w-full"
                                onClick={() => {
                                    navigator.clipboard.writeText(url ?? "");
                                    notificationService.setMessage({
                                        message: "Copied",
                                        status: true,
                                    });
                                }
                                }>
                                <Tooltip text="Click to copy">
                                    <span className='font-semibold text-primary break-all'><FontAwesomeIcon className='ml-2' icon={faCopy} />{url}</span>
                                </Tooltip>
                            </div>
                            3. Copy the correct Instagram url from the new tab in browser and paste it here<br></br>
                        </div>
                    </>
                }
                onClose={() => setIsReusableAcceptCancelModal(false)}
            />
        </>
    );
};

export default InstagramUrlApp;