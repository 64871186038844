import React, { useState } from 'react';
import { MaxWEnum } from '../helpers/FormEnum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import useIsMobile from '../hooks/useIsMobile';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

const Socials: React.FC = () => {
    const { isMobile } = useIsMobile();
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const toggleAnswer = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className={`${MaxWEnum.MaxWXl} w-full`}>
            <div className='mx-auto text-center items-center w-full mt-2 mb-2'>
                <div className={`space-x-6 ${isMobile && "text-center"}`}>
                    <Link to="https://instagram.com/socialspicker" target="_blank" className="group">
                        <FontAwesomeIcon icon={faInstagram} className='text-instagram text-2xl group-hover:text-instagram' />
                    </Link>
                    <Link to="https://tiktok.com/@socialspicker" target="_blank" className="group">
                        <FontAwesomeIcon icon={faTiktok} className='text-tiktok text-2xl group-hover:text-tiktok' />
                    </Link>
                    <Link to="https://x.com/socialspicker" target="_blank" className="group">
                        <FontAwesomeIcon icon={faX} className='text-twitter text-2xl group-hover:text-twitter' />
                    </Link>
                    <Link to="https://facebook.com/socialspicker" target="_blank" className="group">
                        <FontAwesomeIcon icon={faFacebook} className='text-facebook text-2xl group-hover:text-facebook' />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Socials;