import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { participantCreditPackages } from "../config/envConfig";
import { CampaignPreviewDTO } from "../dto/CampaignPreviewDTO";
import { CampaignSettingsDTO } from "../dto/CampaignSettingsDTO";
import { CampaignStateDataDTO } from "../dto/CampaignStateDataDTO";
import { CampaignStatePersistentDTO } from "../dto/CampaignStatePersistentDTO";
import { ParticipantCreditsPackagesDTO } from "../dto/ParticipantCreditsPackagesDTO";
import { WinnerDTO } from "../dto/WinnerDTO";
import { RoutesEnum as AppRoutes } from "../routes/RoutesEnum";
import { CampaignViewEnum } from "./CampaignViewEnum";
import { SocialPlatformEnum } from "./SocialPlatformEnum";
import { StatusEnum } from "./StatusEnum";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faFacebook, faInstagram, faTiktok, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faList, faQuestion } from "@fortawesome/free-solid-svg-icons";

export const setNavigateCampaignState = (navigate: (path: string) => void) => {
    navigate(AppRoutes.CAMPAIGN);
};

export const setCampaignStatePersistent = (campaignStateDataDTO: CampaignStateDataDTO | null, campaignSettingsDTO: CampaignSettingsDTO | null, campaignViewEnum: CampaignViewEnum | null, currentProgressStepIndex: number, campaignParticipants: WinnerDTO[] | null) => {
    if (!campaignStateDataDTO || !campaignViewEnum) {
        return;
    }

    const campaignStatePersistent: CampaignStatePersistentDTO =
    {
        campaignStateDataDTO: campaignStateDataDTO,
        ...(campaignSettingsDTO && { campaignSettingsDTO: campaignSettingsDTO }),
        campaignViewEnum: campaignViewEnum,
        currentProgressStepIndex: currentProgressStepIndex,
        ...(campaignParticipants && { campaignParticipants: campaignParticipants }),
    };

    localStorage.setItem("campaignStatePersistent", JSON.stringify(campaignStatePersistent));
};

export const getCampaignStatePersistent = (): CampaignStatePersistentDTO | null => {
    const campaignStatePersistent = JSON.parse(localStorage.getItem('campaignStatePersistent') || 'null');

    return campaignStatePersistent;
};

export const removeCampaignStatePersistent = () => {
    localStorage.removeItem("campaignStatePersistent");
};

export const getRandomWinners = async (campaignStateDataDTO: CampaignStateDataDTO | null, campaignSettingsDTO: CampaignSettingsDTO | null, filteredCampaignParticipants: WinnerDTO[] | null): Promise<[string, WinnerDTO[] | null, WinnerDTO[] | null]> => {
    return new Promise((resolve, reject) => {

        if (filteredCampaignParticipants && campaignSettingsDTO) {
            var statusMessage = "";

            const winnerAndSubstitutesCount = campaignSettingsDTO.Winners + campaignSettingsDTO.Substitutes;

            const shuffleArray = (array: WinnerDTO[]) => {
                for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                }
                return array;
            };

            const shuffledParticipants = shuffleArray([...filteredCampaignParticipants]);

            const seenUsernames = new Set();
            const filtered = shuffledParticipants?.filter((winner) => {
                if (seenUsernames.has(winner.Username)) {
                    return false;
                } else {
                    seenUsernames.add(winner.Username);
                    return true;
                }
            });

            if (filtered.length < winnerAndSubstitutesCount) {
                statusMessage = "Not enough participants to meet the required number of Winners and Substitutes change the filters or lower the Winners or Substitutes";

                resolve([statusMessage, null, null]);

                return;
            }

            const selectedWinners: WinnerDTO[] = [];
            const selectedSubstitutes: WinnerDTO[] = [];

            for (const participant of filtered) {
                if (selectedWinners.length >= campaignSettingsDTO.Winners) {
                    if (selectedSubstitutes.length >= campaignSettingsDTO.Substitutes) {
                        break;
                    }
                    else {
                        participant.IsSubstitute = true;

                        selectedSubstitutes.push(participant);
                    }
                }
                else {
                    selectedWinners.push(participant);
                }
            }

            resolve([statusMessage, selectedWinners, selectedSubstitutes]);

            return;
        }
        else {
            statusMessage = "Not enough participants to meet the required number of Winners and Substitutes change the filters or lower the Winners or Substitutes";

            resolve([statusMessage, null, null]);

            return;
        }
    });
};

export const getParticipantCreditPackage = (participantCredits: number): ParticipantCreditsPackagesDTO | undefined => {
    return participantCreditPackages.find(packageItem => packageItem.ParticipantCredits >= participantCredits);
}

export const isValidInstagramUrl = (url: string): boolean => {
    try {
        const _url = new URL(url.trim());

        const regex = /^(https?:\/\/)?(www\.)?instagram\.com(\/.*)?$/;

        return regex.test(_url.toString());
    } catch (error) {
        return false;  // Invalid URL format
    }
};

export const isInstagramUsername = (username: string): boolean => {
    try {
        const normalizedUsername = username.trim().replace(/^@/, '');

        const regex = /^[a-zA-Z0-9_]{1,30}$/;

        return regex.test(normalizedUsername);
    }
    catch (error) {
        return false;
    }
}

export const getInstagramUsernameFormatted = (username: string): string => {
    return username.replace('@', '').trim();
}

export const getInstagramShortcodeFromPostOrReelUrl = (url: string): string | null => {
    const match = url.match(/\/(p|reel)\/([^\/?#]+)/);
    return match ? match[2] : null;
}

export const isShortlinkInstagramUrl = (url: string): boolean => {
    const regex = /^(https?:\/\/)?(www\.)?instagram\.com\/share\/[\w\d]+\/?$/;
    return regex.test(url.trim());
};

export const isValidTiktokUrl = (url: string): boolean => {
    const regex = /^(https?:\/\/)?(www\.)?(tiktok\.com\/.*|vm\.tiktok\.com\/[\w\d]+\/?)$/;
    return regex.test(url.trim());
};

export const isShortlinkTiktokUrl = (url: string): boolean => {
    const regex = /^(https?:\/\/)?(vm\.tiktok\.com\/[\w\d]+\/?)$/;
    return regex.test(url.trim());
};

export const isValidYoutubeUrl = (url: string): boolean => {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)(\/.*)?$/;

    return regex.test(url);
}

export const isValidTwitterXUrl = (url: string): boolean => {
    const regex = /^(https?:\/\/)?(www\.)?x\.com(\/.*)?$/;

    return regex.test(url);
}

export const getBgBySocialPlatform = (socialPlatform: SocialPlatformEnum | null | undefined): string => {
    switch (socialPlatform) {
        case SocialPlatformEnum.Facebook:
            return "bg-facebook";
        case SocialPlatformEnum.Instagram:
            return "bg-instagram";
        case SocialPlatformEnum.List:
            return "bg-randomNameWinner";
        case SocialPlatformEnum.Tiktok:
            return "bg-tiktok";
        case SocialPlatformEnum.Twitter:
            return "bg-twitter";
        case SocialPlatformEnum.Youtube:
            return "bg-youtube";
        default:
            return "";
    }
};

export const getTextBySocialPlatform = (socialPlatform: SocialPlatformEnum | null | undefined): string => {
    switch (socialPlatform) {
        case SocialPlatformEnum.Facebook:
            return "text-facebook";
        case SocialPlatformEnum.Instagram:
            return "text-instagram";
        case SocialPlatformEnum.List:
            return "text-randomNameWinner";
        case SocialPlatformEnum.Tiktok:
            return "text-tiktok";
        case SocialPlatformEnum.Twitter:
            return "text-twitter";
        case SocialPlatformEnum.Youtube:
            return "text-youtube";
        default:
            return "";
    }
};

export const getCampaignStatusLabel = (status: StatusEnum | undefined): string | undefined => {
    switch (status) {
        case StatusEnum.Finish:
            return "Resulted";
        case StatusEnum.Ready:
            return "Ready To Start";
        case StatusEnum.Failed:
            return "Failed";
        case StatusEnum.Pending:
            return "In Progress";
        case StatusEnum.InProgress:
            return "In Progress";
    }
}

export const getCampaignStatusClass = (status: StatusEnum | undefined): string | undefined => {
    switch (status) {
        case StatusEnum.Finish:
            return "bg-finish text-white";
        case StatusEnum.Ready:
            return "bg-ready";
        case StatusEnum.Failed:
            return "bg-failed text-white";
        case StatusEnum.InProgress:
            return "bg-inProgress text-white";
        case StatusEnum.Pending:
            return "bg-inProgress text-white";
    }
}

export const getCampaignReadyDaysRemaining = (targetDateStr: string, daysForward: number = 5): string => {
    const targetDate = new Date(targetDateStr);

    // Add the specified number of days forward to the target date
    targetDate.setDate(targetDate.getDate() + daysForward);

    const currentDate = new Date();

    // Calculate the difference in time
    const differenceInTime = targetDate.getTime() - currentDate.getTime();

    // Calculate the difference in days
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    // Return the formatted string based on the difference
    if (differenceInDays > 0) {
        return `${differenceInDays} days left`;
    } else if (differenceInDays === 0) {
        return `Last day`;
    } else {
        return `Last day`;
    }
};

export const getSocialPlatformIconBySocialPlatform = (socialPlatform: SocialPlatformEnum | null | undefined): IconDefinition => {
    switch (socialPlatform) {
        case SocialPlatformEnum.Facebook:
            return faFacebook;
        case SocialPlatformEnum.Instagram:
            return faInstagram;
        case SocialPlatformEnum.List:
            return faList;
        case SocialPlatformEnum.Tiktok:
            return faTiktok;
        case SocialPlatformEnum.Twitter:
            return faTwitter;
        case SocialPlatformEnum.Youtube:
            return faYoutube;
        default:
            return faQuestion;
    }
};

export const getCampaignAuthorProfileLinkBySocialPlatform = (socialPlatform: SocialPlatformEnum | null | undefined, username: string | null | undefined): string | null => {
    switch (socialPlatform) {
        case SocialPlatformEnum.Facebook:
            return null;
        case SocialPlatformEnum.Instagram:
            return "https://instagram.com/" + username;
        case SocialPlatformEnum.List:
            return null;
        case SocialPlatformEnum.Tiktok:
            return "https://tiktok.com/@" + username;
        case SocialPlatformEnum.Twitter:
            return "https://x.com/" + username;
        case SocialPlatformEnum.Youtube:
            return "https://youtube.com/@" + username;
        default:
            return null;
    }
};

export const generateCampaignCodeUrl = (code: string, absolutePath: boolean = true) => {
    if (absolutePath)
        return process.env.REACT_APP_FRONTEND_URL as string + "/c/" + code;
    else
        return "/c/" + code;
};

export const canBeValidCampaignCode = (code: string): boolean => {
    if (code.length === 7 || code.length === 8) {
        return true;
    }
    else {
        return false;
    }
};