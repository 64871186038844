
import { Link } from "react-router-dom";
import { NavigationItem } from "../dto/NavigationItem";
import { isRouteActive } from "./RoutesHelper";
import { NavigationItemTypeEnum } from "./NavigationItemTypeEnum";
import { getBackgroundActive, getBackgroundHover, getButtonPrimaryStyleClass } from "../config/FormSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Location } from "react-router-dom";

export const GenerateNavigationItem = (navigationItem: NavigationItem, location: Location) => {
  return (
    <>
      {navigationItem.NavigationItemType === NavigationItemTypeEnum.ItemLink && (
        !navigationItem.Icon && (
          <Link to={navigationItem.Route} className={`hover:${navigationItem.TextColor} 
            ${isRouteActive(location, navigationItem.Route) ?
              navigationItem.TextColor :
              ''}`}>
            {navigationItem.Name}
          </Link>
        )
      )}
      {navigationItem.NavigationItemType === NavigationItemTypeEnum.App && (
        <>
          <Link to={navigationItem.Route} className={`block pl-2 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, navigationItem.Route) ?
              getBackgroundActive() :
              ''}`}>
            {navigationItem.Icon != null && (
              <FontAwesomeIcon icon={navigationItem.Icon} className={`mr-2 group-hover:text-white
                          ${isRouteActive(location, navigationItem.Route) ? "text-white" : navigationItem.TextColor}
                          `} />
            )}
            {navigationItem.Name}
          </Link>
        </>
      )}
      {navigationItem.NavigationItemType === NavigationItemTypeEnum.Logout && (
        <>
          <button onClick={navigationItem.OnClickHandler} className={`w-full text-left px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()}`}>
            {navigationItem.Icon != null && (
              <FontAwesomeIcon icon={navigationItem.Icon} className={`mr-2 ${navigationItem.TextColor} group-hover:text-white`} />
            )}
            {navigationItem.Name}
          </button>
        </>
      )}
      {navigationItem.NavigationItemType === NavigationItemTypeEnum.Signup && (
        !navigationItem.Icon && (
          <Link to={navigationItem.Route} className={`${getButtonPrimaryStyleClass(false)} 
            ${isRouteActive(location, navigationItem.Route) ?
              navigationItem.TextColor :
              ''}`}>
            {navigationItem.Name}
          </Link>
        )
      )}
      {navigationItem.NavigationItemType === NavigationItemTypeEnum.DashboardMenu && (
        <>
          <Link to={navigationItem.Route} className={`block px-4 py-2 rounded text-gray-800 group ${navigationItem.BackgroundColor ? `${navigationItem.BackgroundColor}` : ''} ${getBackgroundHover()} 
                        ${isRouteActive(location, navigationItem.Route) ?
              getBackgroundActive() :
              ''}`}>
            <div className="flex items-center">
              {navigationItem.Icon != null && (
                <div className="flex-col min-w-[30px]">
                  <FontAwesomeIcon icon={navigationItem.Icon} className={`mr-2 
                            ${isRouteActive(location, navigationItem.Route) ?
                      'text-white' :
                      'text-primary group-hover:text-white'} `} />
                </div>
              )}
              <div className="flex-col">
                {navigationItem.Name}
              </div>
            </div>
          </Link>
        </>
      )}
    </>
  );
}