import React, { useState } from 'react';
import './Faq.css';
import { MaxWEnum } from '../../helpers/FormEnum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { getBackgroundHover, getTextLinkStyleClass } from '../../config/FormSettings';
import { Link } from 'react-router-dom';
import useIsMobile from '../../hooks/useIsMobile';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import Socials from '../Socials';

interface Props {
    faq: {
        question: string;
        answer?: string;
        image?: string;
    }[]
}

const Faq: React.FC<Props> = ({ faq }) => {
    const { isMobile } = useIsMobile();
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const toggleAnswer = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className={`${MaxWEnum.MaxWXl} w-full`}>
            <dl className="faq-list w-full">
                {faq.map((item, index) => (
                    <div key={index} className="faq-item">
                        <dt>
                            <button
                                className={`faq-question w-full ${getBackgroundHover()}`}
                                type="button"
                                aria-expanded={openIndex === index}
                                onClick={() => toggleAnswer(index)}
                            >
                                <span>{item.question}</span>
                                <span className="icon">
                                    {openIndex === index ? (
                                        <FontAwesomeIcon icon={faMinus} />
                                    ) : (
                                        <FontAwesomeIcon icon={faPlus} />
                                    )}
                                </span>
                            </button>
                        </dt>
                        {openIndex === index && (
                            <>
                                {item.image && (
                                    <img src={`${item.image}`} className="w-full max-h-96 object-contain" />
                                )}
                                {item.answer && (
                                    <dd className="faq-answer w-full text-sm">
                                        <pre style={{ fontFamily: "inherit", fontSize: "inherit", whiteSpace: "pre-wrap", wordWrap: "break-word" }} className='whitespace-pre-line text-base leading-7'>{item.answer}</pre>
                                    </dd>
                                )}
                            </>
                        )}
                    </div>
                ))}
            </dl>

            <div className='mx-auto text-center items-center w-full mt-8'>
                <p className='font-semibold text-lg'>Do you have more questions?</p>
                <Link className={`${getTextLinkStyleClass()}`} to="mailto:hq@socialspicker.com">hq@socialspicker.com</Link>
                <div className='mt-2'>
                    OR
                </div>
                <Socials />
            </div>
        </div>
    );
};

export default Faq;