import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import React from 'react';
import { navigationAppsItems } from '../config/envConfig';
import { AppsNavigationItem, NavigationItem } from '../dto/NavigationItem';
import { getBackgroundHover, getCardBackgroundPrimary, getStandardCardBackground } from '../config/FormSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { MaxWEnum } from '../helpers/FormEnum';

const Dashboard: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Create Giveaways & Promotions Apps</title>
      </Helmet>

      {navigationAppsItems.map((header: AppsNavigationItem) => (
        <React.Fragment key={header.Header.Name}>
          <div className={`${getStandardCardBackground(false, MaxWEnum.None, undefined, undefined, true, undefined, false)} my-2`}>
            <div className="mb-2 text-gray-500 pl-2">{header.Header.Name}</div>
            <div className="flex flex-col md:flex-row items-center">
              {header.Header.Items.map((item: NavigationItem) => (
                <React.Fragment key={item.Name}>
                  <Link to={item.Route} className="md:p-2 p-2 flex flex-col group-hover:text-white w-full group-hover:cursor-pointer">
                    <div className={`${getStandardCardBackground(true, MaxWEnum.None, undefined, "m-1", true, undefined, true)} ${getBackgroundHover()} group min-h-40 cursor-pointer`}>
                      <div className="mr-2 w-full text-center min-h-10">
                        <FontAwesomeIcon
                          icon={item.Icon ?? faQuestion}
                          className={`
                          ${item.TextColor}
                          w-full min-h-10
                        `} />
                      </div>
                      <div className="text-gray-800 group-hover:text-white w-full text-center">
                        {item.Name}
                      </div>
                    </div>
                  </Link>
                </React.Fragment>
              ))}
            </div>
          </div>
        </React.Fragment>
      ))}
    </Layout>
  );
};

export default Dashboard;