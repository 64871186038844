import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faDice, faRandom, faCoins, faUser, faCreditCard, faSignOutAlt, faArrowAltCircleUp, faThLarge, faUserCircle, faUserShield, faQuestion, faListDots, faX, faSearch, faListNumeric, faImagePortrait, faImage, faGrip, faSignOut, faChartArea } from '@fortawesome/free-solid-svg-icons';
import useIsMobile from '../../hooks/useIsMobile';
import { RoutesEnum as AppRoutes, RoutesEnum } from '../../routes/RoutesEnum';
import { RolesEnum as Roles } from '../../auth/helpers/RolesEnum';
import { useAuthContext } from '../../auth/contexts/AuthContext';
import { getDefinedRoutesWithoutParams, isRouteActive } from '../../helpers/RoutesHelper';
import { getBackgroundActive, getBackgroundHover, getButtonPrimaryStyleClass, getButtonSuccessStyleClass } from '../../config/FormSettings';
import { navigationAppsItems } from '../../config/envConfig';
import { AppsNavigationItem, NavigationItem } from '../../dto/NavigationItem';
import { GenerateNavigationItem } from '../../helpers/NavigationHelper';
import { NavigationItemTypeEnum } from '../../helpers/NavigationItemTypeEnum';
import Credits from '../Credits';

const Navigation: React.FC = () => {
  const { isMobile } = useIsMobile();
  const location = useLocation();
  const { userData, logout, isLoggedInState } = useAuthContext();
  const desktopAppsButtonRef = useRef<HTMLButtonElement>(null);
  const desktopAppsDropdownRef = useRef<HTMLDivElement>(null);
  const mobileAppsButtonRef = useRef<HTMLButtonElement>(null);
  const mobileAppsDropdownRef = useRef<HTMLDivElement>(null);
  const desktopDashboardButtonRef = useRef<HTMLButtonElement>(null);
  const desktopDashboardDropdownRef = useRef<HTMLDivElement>(null);
  const mobileDashboardButtonRef = useRef<HTMLButtonElement>(null);
  const mobileDashboardDropdownRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLElement | null>(null);
  const [navHeight, setNavHeight] = useState<number>(0);
  const [maxHeightUserMenuMobile] = useState('calc(100vh - 93px)');
  const [maxHeightAppsMenuMobile] = useState('calc(100vh - 65px)');
  const [isOpenDashboardMenu, setIsOpenDashboardMenu] = useState(false);
  const [isOpenAppsMenu, setIsOpenAppsMenu] = useState(false);

  useEffect(() => {
    if (navRef.current) {
      const height = navRef.current.getBoundingClientRect().height;
      setNavHeight(height);
    }

    document.removeEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobile]);

  const handleClickOutside = (event: MouseEvent) => {
    if (!isMobile) {
      if (
        desktopAppsDropdownRef.current &&
        !desktopAppsDropdownRef.current.contains(event.target as Node) &&
        desktopAppsButtonRef.current &&
        !desktopAppsButtonRef.current.contains(event.target as Node)
      ) {
        setIsOpenAppsMenu(false);
      }

      if (
        desktopDashboardDropdownRef.current &&
        !desktopDashboardDropdownRef.current.contains(event.target as Node) &&
        desktopDashboardButtonRef.current &&
        !desktopDashboardButtonRef.current.contains(event.target as Node)
      ) {
        setIsOpenDashboardMenu(false);
      }
    }
    else {
      if (
        mobileAppsDropdownRef.current &&
        !mobileAppsDropdownRef.current.contains(event.target as Node) &&
        mobileAppsButtonRef.current &&
        !mobileAppsButtonRef.current.contains(event.target as Node)
      ) {
        setIsOpenAppsMenu(false);
      }

      if (
        mobileDashboardDropdownRef.current &&
        !mobileDashboardDropdownRef.current.contains(event.target as Node) &&
        mobileDashboardButtonRef.current &&
        !mobileDashboardButtonRef.current.contains(event.target as Node)
      ) {
        setIsOpenDashboardMenu(false);
      }
    }
  };

  const toggleDashboardMenu = () => {
    setIsOpenDashboardMenu(!isOpenDashboardMenu);
    if (!isOpenDashboardMenu) {
      setIsOpenAppsMenu(false);
    }
  };

  const toggleAppsMenu = () => {
    if (navRef.current) {
      const height = navRef.current.getBoundingClientRect().height;
      setNavHeight(height);
    }

    setIsOpenAppsMenu(!isOpenAppsMenu);
    if (!isOpenAppsMenu) {
      setIsOpenDashboardMenu(false);
    }
  };

  const handleLogout = async () => {
    await logout();
  };

  return (
    <>
      <nav ref={navRef} className="bg-white md:p-2 text-black fixed top-0 left-0 right-0 border-b border-gray-300 z-50">
        <div className="container mx-auto flex justify-between items-center">
          <Link to={AppRoutes.HOME} className={`flex items-center ${isMobile ? "ml-1 py-1" : ""}`}>
            <img src={isMobile || isLoggedInState ? "/assets/logoIconBlack.svg" : "/assets/logoBlack.svg"} alt="Logo" className={isMobile || isLoggedInState ? "w-full h-auto max-w-01xs" : "w-full h-auto max-w-3xs"} />
          </Link>

          {/*start desktop menu*/}
          <div className="hidden md:flex space-x-4 items-center">
            {isLoggedInState && (
              GenerateNavigationItem({
                Name: "My Campaigns",
                Icon: null,
                NavigationItemType: NavigationItemTypeEnum.ItemLink,
                TextColor: "text-secondary",
                Route: RoutesEnum.DASHBOARD,
              }, location)
            )}
            {/*apps menu*/}
            <div className="relative">
              <button ref={desktopAppsButtonRef} onClick={toggleAppsMenu} className={`flex items-center group hover:text-secondary ${isOpenAppsMenu && "text-secondary"}`}>
                <FontAwesomeIcon icon={faGrip} className={`${isOpenAppsMenu ? "text-secondary" : "text-primary"} mr-2 group-hover:text-secondary`} />
                Apps
                <svg
                  className={`ml-2 h-4 w-4 transition-transform ${isOpenAppsMenu ? 'rotate-180' : 'rotate-0'}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              {isOpenAppsMenu && (
                <div ref={desktopAppsDropdownRef} className="fixed left-0 w-screen bg-white border border-gray-300 rounded shadow-lg z-50 overflow-y-auto" style={{ top: navHeight + "px", maxHeight: maxHeightAppsMenuMobile }}>
                  <div className="container mx-auto py-4">
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                      {navigationAppsItems.map((header: AppsNavigationItem) => (
                        <React.Fragment key={header.Header.Name}>
                          <div>
                            <div className="mb-2 text-gray-500 pl-2">{header.Header.Name}</div>
                            {header.Header.Items.map((item: NavigationItem) => (
                              <React.Fragment key={item.Name}>
                                {GenerateNavigationItem(item, location)}
                              </React.Fragment>
                            ))}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/*apps menu - end*/}
            {!isLoggedInState && (
              GenerateNavigationItem({
                Name: "Prices",
                Icon: null,
                NavigationItemType: NavigationItemTypeEnum.ItemLink,
                TextColor: "text-secondary",
                Route: RoutesEnum.PLANS,
              }, location)
            )}
            {GenerateNavigationItem({
              Name: "Help",
              Icon: null,
              NavigationItemType: NavigationItemTypeEnum.ItemLink,
              TextColor: "text-secondary",
              Route: RoutesEnum.HELP,
            }, location)}
          </div>
          <div className="hidden md:flex space-x-4 items-center">
            <div>
              <Credits />
            </div>
            {isLoggedInState ? (
              <>
                <div className="relative hl">
                  <button ref={desktopDashboardButtonRef} onClick={toggleDashboardMenu} className={`flex items-center group hover:text-secondary ${isOpenDashboardMenu && "text-secondary"}`}>
                    <FontAwesomeIcon icon={faUserCircle} className={`mr-2 ${isOpenDashboardMenu ? 'text-secondary' : 'text-primary'} group-hover:text-secondary`} size="2x" />
                    <svg
                      className={`ml-2 h-4 w-4 transition-transform ${isOpenDashboardMenu ? 'rotate-180' : 'rotate-0'} group-hover:text-secondary`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                  {isOpenDashboardMenu && (
                    <div ref={desktopDashboardDropdownRef} className="absolute right-0 mt-2 w-56 bg-white border border-gray-300 rounded shadow-lg z-10 overflow-y-auto max-h-[75vh]">
                      <div className="py-1">
                        {userData?.Role === Roles.ADMIN && (
                          GenerateNavigationItem({
                            Name: "Admin Dashboard",
                            Icon: faChartArea,
                            NavigationItemType: NavigationItemTypeEnum.DashboardMenu,
                            TextColor: "text-primary",
                            Route: RoutesEnum.ADMIN_DASHBOARD,
                          }, location)
                        )}
                        {GenerateNavigationItem({
                          Name: "My Campaigns",
                          Icon: faListDots,
                          NavigationItemType: NavigationItemTypeEnum.DashboardMenu,
                          TextColor: "text-primary",
                          Route: RoutesEnum.DASHBOARD,
                        }, location)}
                        {GenerateNavigationItem({
                          Name: "Account",
                          Icon: faUser,
                          NavigationItemType: NavigationItemTypeEnum.DashboardMenu,
                          TextColor: "text-primary",
                          Route: getDefinedRoutesWithoutParams(RoutesEnum.P) + "@" + decodeURIComponent(userData?.Username ?? ""),
                        }, location)}
                        {GenerateNavigationItem({
                          Name: "Billing",
                          Icon: faCreditCard,
                          NavigationItemType: NavigationItemTypeEnum.DashboardMenu,
                          TextColor: "text-primary",
                          Route: RoutesEnum.BILLING,
                        }, location)}
                        {GenerateNavigationItem({
                          Name: "Help",
                          Icon: faQuestion,
                          NavigationItemType: NavigationItemTypeEnum.DashboardMenu,
                          TextColor: "text-primary",
                          Route: RoutesEnum.HELP,
                        }, location)}
                        <div className="border-t border-gray-200"></div>
                        {GenerateNavigationItem({
                          Name: "Logout",
                          Icon: faSignOut,
                          NavigationItemType: NavigationItemTypeEnum.Logout,
                          TextColor: "text-primary",
                          Route: "",
                          OnClickHandler: handleLogout
                        }, location)}
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                {GenerateNavigationItem({
                  Name: "Login",
                  Icon: null,
                  NavigationItemType: NavigationItemTypeEnum.ItemLink,
                  TextColor: "text-secondary",
                  Route: RoutesEnum.LOGIN
                }, location)}
                {GenerateNavigationItem({
                  Name: "Signup",
                  Icon: null,
                  NavigationItemType: NavigationItemTypeEnum.Signup,
                  TextColor: "text-secondary",
                  Route: RoutesEnum.SIGNUP,
                }, location)}
              </>
            )}
          </div>
          {/*end desktop menu*/}

          {/*start mobile menu*/}
          {/*apps menu*/}
          <div className="md:hidden py-2 flex items-center">
            <div className="relative">
              <button ref={mobileAppsButtonRef} onClick={toggleAppsMenu} className={`flex items-center group hover:text-secondary ${isOpenAppsMenu && "text-secondary"}`}>
                <FontAwesomeIcon icon={faThLarge} className={`${isOpenAppsMenu ? "text-secondary" : "text-primary"} mr-2 group-hover:text-secondary`} />
                Apps
                <svg
                  className={`ml-2 h-4 w-4 transition-transform ${isOpenAppsMenu ? 'rotate-180' : 'rotate-0'}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              {isOpenAppsMenu && (
                <div ref={mobileAppsDropdownRef} className="fixed left-0 w-screen bg-white border border-gray-300 rounded shadow-lg z-50 overflow-y-auto" style={{ top: navHeight + "px", maxHeight: maxHeightAppsMenuMobile }}>
                  <div className="container mx-auto py-4">
                    <div className="grid grid-cols-2 gap-2">
                      {navigationAppsItems.map((header: AppsNavigationItem) => (
                        <React.Fragment key={header.Header.Name}>
                          <div>
                            <div className="mb-2 text-gray-500 pl-2">{header.Header.Name}</div>
                            {header.Header.Items.map((item: NavigationItem) => (
                              <React.Fragment key={item.Name}>
                                {GenerateNavigationItem(item, location)}
                              </React.Fragment>
                            ))}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/*apps menu - end*/}
          <div className="md:hidden py-2 mr-1 flex items-center">
            <button ref={mobileDashboardButtonRef} onClick={toggleDashboardMenu}>
              {isOpenDashboardMenu ? (
                <XMarkIcon className="h-6 w-6" />
              ) : (
                <Bars3Icon className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
        {isOpenDashboardMenu && (
          <div ref={mobileDashboardDropdownRef} className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 overflow-y-auto" style={{ maxHeight: maxHeightUserMenuMobile }}>
              <div>
                <Credits />
              </div>
              {isLoggedInState ? (
                <>
                  {userData?.Role === Roles.ADMIN && (
                    GenerateNavigationItem({
                      Name: "Admin Dashboard",
                      Icon: faChartArea,
                      NavigationItemType: NavigationItemTypeEnum.DashboardMenu,
                      TextColor: "text-primary",
                      Route: RoutesEnum.ADMIN_DASHBOARD,
                    }, location)
                  )}
                  {GenerateNavigationItem({
                    Name: "My Campaigns",
                    Icon: faListDots,
                    NavigationItemType: NavigationItemTypeEnum.DashboardMenu,
                    TextColor: "text-primary",
                    Route: RoutesEnum.DASHBOARD,
                  }, location)}
                  {GenerateNavigationItem({
                    Name: "Account",
                    Icon: faUser,
                    NavigationItemType: NavigationItemTypeEnum.DashboardMenu,
                    TextColor: "text-primary",
                    Route: getDefinedRoutesWithoutParams(RoutesEnum.P) + "@" + decodeURIComponent(userData?.Username ?? ""),
                  }, location)}
                  {GenerateNavigationItem({
                    Name: "Billing",
                    Icon: faCreditCard,
                    NavigationItemType: NavigationItemTypeEnum.DashboardMenu,
                    TextColor: "text-primary",
                    Route: RoutesEnum.BILLING,
                  }, location)}
                  {GenerateNavigationItem({
                    Name: "Help",
                    Icon: faQuestion,
                    NavigationItemType: NavigationItemTypeEnum.DashboardMenu,
                    TextColor: "text-primary",
                    Route: RoutesEnum.HELP,
                  }, location)}
                  <div className="border-t border-gray-200"></div>
                  {GenerateNavigationItem({
                    Name: "Logout",
                    Icon: faSignOut,
                    NavigationItemType: NavigationItemTypeEnum.Logout,
                    TextColor: "text-primary",
                    Route: "",
                    OnClickHandler: handleLogout
                  }, location)}
                </>
              ) : (
                <>
                  {GenerateNavigationItem({
                    Name: "Prices",
                    Icon: null,
                    NavigationItemType: NavigationItemTypeEnum.DashboardMenu,
                    TextColor: "text-secondary",
                    Route: RoutesEnum.PLANS,
                  }, location)}
                  {GenerateNavigationItem({
                    Name: "Help",
                    Icon: null,
                    NavigationItemType: NavigationItemTypeEnum.DashboardMenu,
                    TextColor: "text-secondary",
                    Route: RoutesEnum.HELP,
                  }, location)}
                  {GenerateNavigationItem({
                    Name: "Login",
                    Icon: null,
                    NavigationItemType: NavigationItemTypeEnum.DashboardMenu,
                    TextColor: "text-secondary",
                    Route: RoutesEnum.LOGIN,
                  }, location)}
                  {GenerateNavigationItem({
                    Name: "Signup",
                    Icon: null,
                    NavigationItemType: NavigationItemTypeEnum.DashboardMenu,
                    TextColor: "text-secondary",
                    BackgroundColor: "bg-primary text-white",
                    Route: RoutesEnum.SIGNUP,
                  }, location)}
                </>
              )}
            </div>
          </div>
        )}
        {/*end mobile menu*/}
      </nav>
    </>
  );
};

export default Navigation;