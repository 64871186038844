import React, { useState } from 'react';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import InstagramUrlApp from '../components/campaign/apps/InstagramUrlApp';
import { Link } from 'react-router-dom';
import { RoutesEnum } from '../routes/RoutesEnum';
import { getButtonPrimaryStyleClass } from '../config/FormSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPlay } from '@fortawesome/free-solid-svg-icons';
import VideoIframe from '../components/VideoIframe';

const Home: React.FC = () => {
  const [isDisplayVideo, setIsDisplayVideo] = useState<boolean>(false);

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Comment Picker and Giveaways for Instagram, TikTok, Youtube, X and more</title>
      </Helmet>

      <div className="container relative mt-10 mb-20">
        <div className="flex flex-wrap lg:flex-nowrap gap-6">
          {/* Left Column */}
          <div className="lg:w-5/12">
            <div className="mb-5">
              <h1 className="text-4xl lg:text-5xl font-bold bg-gradient-to-r from-primary to-dark bg-clip-text text-primary">
                Giveaway Running as easy as it gets
              </h1>
              <p className="text-lg text-gray-700 mt-4">
                Pick winners from comments, likes, retweets etc. Filter participants,
                check rules, and publish / share results with certification.
              </p>
            </div>
            <div className="flex gap-2 items-center mb-2">
              <div>
                <Link to={RoutesEnum.APPS} className={`${getButtonPrimaryStyleClass(false)}`}>
                  Try Now
                  <FontAwesomeIcon className='ml-1' icon={faArrowRight} />
                </Link>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="lg:w-7/12 flex justify-center items-center mt-5 lg:mt-0 w-full">
            <div className={`ratio ratio-16x9 w-full h-full ${isDisplayVideo ? "min-h-80" : ""} shadow-primary-lg rounded aspect-ratio-16-9 position-relative`}>
              {!isDisplayVideo ? (
                <>
                  <div className="rounded overflow-hidden relative">
                    <img
                      src="/assets/img/thumbnail-video.png"
                      alt="Socials Picker presentation video"
                      className="w-full h-full"
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <button type="button" onClick={() => setIsDisplayVideo(true)} className="bg-white rounded-full shadow-md opacity-75 w-20 h-20 flex items-center justify-center">
                        <FontAwesomeIcon icon={faPlay} />
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <VideoIframe />
              )}
            </div>
          </div>
        </div>
      </div>

      <InstagramUrlApp />

      <div className="container relative mt-10 mb-2">
        <div className="flex flex-wrap lg:flex-nowrap gap-6">
          {/* Left Column */}
          <div className="lg:w-7/12 flex justify-center items-center mt-5 lg:mt-0 w-full">
            <div className="ratio ratio-16x9 w-full h-full shadow-primary-lg rounded aspect-ratio-16-9 position-relative">
              <div className="rounded overflow-hidden relative">
                <img
                  src="/assets/img/shareCertificate.png"
                  alt="Socials Picker presentation video"
                  className="w-full h-full"
                />
              </div>
            </div>
          </div>
          {/* Right Column */}
          <div className="lg:w-5/12">
            <div className="mb-5">
              <h1 className="text-4xl lg:text-5xl font-bold bg-gradient-to-r from-primary to-dark bg-clip-text text-primary">
                Get a certificate to share the winners of your giveaway
              </h1>
              <p className="text-lg text-gray-700 mt-4">
                Share transparency with your followers! At the end of the giveaway, you can share the winners with a video and a certificate.
              </p>
            </div>
            <div className="flex gap-2 items-center mb-2">
              <div>
                <Link to={RoutesEnum.APPS} className={`${getButtonPrimaryStyleClass(false)}`}>
                  Try Now
                  <FontAwesomeIcon className='ml-1' icon={faArrowRight} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container relative mt-10 mb-8">
        <div className="flex flex-wrap lg:flex-nowrap gap-6">
          {/* Left Column */}
          <div className="lg:w-7/12 flex justify-center items-center mt-5 lg:mt-0 w-full">
            <div className="ratio ratio-16x9 w-full h-full shadow-primary-lg rounded aspect-ratio-16-9 position-relative">
              <div className="rounded overflow-hidden relative">
                <img
                  src="/assets/img/filters.png"
                  alt="Socials Picker presentation video"
                  className="w-full h-full"
                />
              </div>
            </div>
          </div>
          {/* Right Column */}
          <div className="lg:w-5/12">
            <div className="mb-5">
              <h1 className="text-4xl lg:text-5xl font-bold bg-gradient-to-r from-primary to-dark bg-clip-text text-primary">
                Select a random winner, only from comments that meet the requirements
              </h1>
              <p className="text-lg text-gray-700 mt-4">
                With Socials Picker you can quickly filter users who meet the proposed requirements.
              </p>
            </div>
            <div className="flex gap-2 items-center mb-2">
              <div>
                <Link to={RoutesEnum.APPS} className={`${getButtonPrimaryStyleClass(false)}`}>
                  Try Now
                  <FontAwesomeIcon className='ml-1' icon={faArrowRight} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default Home;